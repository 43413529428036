import React, { FunctionComponent, ReactNode, Fragment } from 'react';
import Head from 'next/head';
import Header from '../shared/Header';
import Main from '../register/Main';

interface Props {
  title?: string;
  metaDescription?: string;
  children?: ReactNode;
}

const LoginLayout: FunctionComponent<Props> = ({
  title,
  metaDescription,
  children,
}: Props) => {
  const pageTitle = title ? `MDLIVE | ${title}` : 'MDLIVE';

  return (
    <Fragment>
      <Head>
        <title>{pageTitle}</title>
        {metaDescription && (
          <meta name="description" content={metaDescription} />
        )}
      </Head>
      <Header />
      <Main pt={['sm', 'md']}>{children}</Main>
    </Fragment>
  );
};

export default LoginLayout;
